/*
========================================
flex
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex: [value];
----------------------------------------
example:
  .flex-1 {
    flex: 1 0 0;
  }

  .flex-fill {
    flex: 1 0 0;
  }

  .flex-auto {
    flex: 0 1 auto;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-flex: (
  flex: (
    base: "flex",
    modifiers: null,
    values: map-collect(get-palettes($flex-palettes), $flex-manual-values),
    settings: $flex-settings-complete,
    property: "flex",
    type: "utility",
  ),
);
