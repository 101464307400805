/*
----------------------------------------
Line height
----------------------------------------
*/

$system-line-height: (
  1: 1,
  2: 1.2,
  3: 1.35,
  4: 1.5,
  5: 1.62,
  6: 1.75,
);
