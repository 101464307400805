/*
========================================
min-width
----------------------------------------
usage:
  .minw-[key]
----------------------------------------
output:
  min-width: [value];
----------------------------------------
example:
  .minw-15 {
    min-width: 7.5rem;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-min-width: (
  min-width: (
    base: "minw",
    modifiers: null,
    values:
      map-collect(get-palettes($min-width-palettes), $min-width-manual-values),
    settings: $min-width-settings-complete,
    property: "min-width",
    type: "utility",
  ),
);
