/*
----------------------------------------
to-number()
----------------------------------------
Casts a string into a number
----------------------------------------
@param {String | Number} $value - Value to be parsed
@return {Number}
----------------------------------------
*/

@use "sass:meta";
@use "sass:map";
@use "sass:string";
@use "sass:list";

@function to-number($value) {
  @if meta.type-of($value) == "number" {
    @return $value;
  } @else if meta.type-of($value) != "string" {
    @warn "Value for `to-number` should be a number or a string.";
  }

  $result: 0;
  $digits: 0;
  $minus: string.slice($value, 1, 1) == "-";
  $numbers: (
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
  );

  @for $i from if($minus, 2, 1) through string.length($value) {
    $character: string.slice($value, $i, $i);

    @if not(list.index(map.keys($numbers), $character) or $character == ".") {
      @return to-length(
        if($minus, -$result, $result),
        string.slice($value, $i)
      );
    }

    @if $character == "." {
      $digits: 1;
    } @else if $digits == 0 {
      $result: $result * 10 + map.get($numbers, $character);
    } @else {
      $digits: $digits * 10;
      $result: $result + math.div(map.get($numbers, $character), $digits);
    }
  }

  @return if($minus, -$result, $result);
}
