/*
----------------------------------------
uswds-error()
----------------------------------------
Allow the system to pass an error as text
to test error states in unit testing
----------------------------------------
*/

$error-output-override: false !default;
@function uswds-error($message, $override: $error-output-override) {
  @if $override {
    @return "Error: #{$message}";
  }

  @error "#{$message}";
}
