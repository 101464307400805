/*  notifications.scss
    ---
    Adds a notification at the top of each USWDS
    compile. Use this file for important notifications
    and updates to the design system.

    This file should started fresh at each
    major version.

*/

@use "settings" as *;

/* prettier-ignore */
$uswds-notifications:
  "\A"
+ "\A --------------------------------------------------------------------"
+ "\A \2709  USWDS Notifications"
+ "\A --------------------------------------------------------------------"
+ "\A 3.0.0"
+ "\A - Settings: We deprecated the $output-all-utilities setting."
+ "\A   Control utility output with $output-these-utilities."
+ "\A   See designsystem.digital.gov/documentation/settings for more."
+ "\A "
+ "\A --------------------------------------------------------------------"
+ "\A 3.1.0"
+ "\A - Accessibility: We added `type='button'` to all buttons that"
+ "\A   do not submit a form. All your non-form project buttons will"
+ "\A   need `type='button'`."
+ "\A - Accessibility: We updated the markup in the usa-password package"
+ "\A   to use a <button> element instead of an anchor element."
+ "\A - Accessibility: Elements that get `aria-disabled` instead of"
+ "\A   `disabled` are now styled as disabled."
+ "\A - Compatibility: We're using some CSS selectors not supported"
+ "\A   by IE11."
+ "\A - Deprecated: We deprecated the embed-container() mixin."
+ "\A - Display: We removed the outline circle from social media icons."
+ "\A - Settings: We changed the default value of $theme-hero-image."
+ "\A   Teams that use the default image (hero.png) will need to get the"
+ "\A   new image (hero.jpg) from the distribution package."
+ "\A --------------------------------------------------------------------"
+ "\A 3.2.0"
+ "\A - Accessibility: We changed `div.usa-identifier__identity` to "
+ "\A   `section.usa-identifier__identity` (we changed the div to a "
+ "\A   section) so the ARIA label is associated with an non-div element."
+ "\A - Accessibility: We updated the aria-label in English versions of"
+ "\A   section.usa-banner to 'Official website of the United States "
+ "\A   government' to reduce ambiguity in screen reader vocalizations. "
+ "\A   Previously, 'An official' could be misunderstood as 'unofficial'."
+ "\A - Accessibility: We added `aria-hidden='true'` to the parent div "
+ "\A   of `p.usa-banner__header-text` in usa-banner. We also "
+ "\A   removed `aria-hidden='true'` from `p.usa-banner__header-action`. "
+ "\A   Together these assure that the proper elements are read in the"
+ "\A   banner."
+ "\A - Accessibility: In the lock icon SVG in usa-banner, we changed the "
+ "\A   value of aria-labelledby to  "
+ "\A   `aria-labelledby='banner-lock-description'` and updated the value"
+ "\A   of desc#banner-lock-description to `'Locked padlock'` to simplify"
+ "\A   the vocalization."
+ "\A - Accessibility: We added `aria-hidden='true' to "
+ "\A   `img.usa-banner__header-flag` in usa-banner since this is a"
+ "\A   decorative image."
+ "\A --------------------------------------------------------------------"
+ "\A 3.4.0"
+ "\A - Compatibility: We no longer include ttf and woff fonts in our "
+ "\A   @font-face rules. If you need these files for IEll compatibility,"
+ "\A   add `$theme-font-browser-compatibility: true` to your project"
+ "\A   settings."
+ "\A --------------------------------------------------------------------"
+ "\A 3.5.0"
+ "\A - Sass: Removed `usa-prose-` mixins and placeholders from Sass."
+ "\A   This is a breaking change only if your project Sass uses "
+ "\A   `usa-prose-` mixins and placeholders. Users should instead use "
+ "\A   `typeset-` mixins in their place."
+ "\A - Settings: Now adjusting a single Sass map setting will no "
+ "\A   longer set all other values in the map to `false`. Users can"
+ "\A   now update only the map values they wish to change. This is a "
+ "\A   breaking change only if your project deactivated mapped "
+ "\A   settings by not declaring them."
+ "\A - Accessibility: [form controls] We improved the consistency and "
+ "\A   visibility of disabled styles. Teams should check disabled form "
+ "\A   elements to assure that the new styles are working in their "
+ "\A   layouts."
+ "\A - Accessibility: [usa-pagination] Replace the "
+ "\A   `role=\"presentation\"` with `aria-label=\"ellipsis indicating "
+ "\A   non-visible pages\" in `usa-pagination__overflow` items."
+ "\A - Accessibility: [usa-card] Use link elements in"
+ "\A   any card component for calls to action instead of buttons. Use "
+ "\A   `<a href=\"#\" class=\"usa-button\"></a>` for these "
+ "\A   call-to-action links."
+ "\A - Content: [usa-identifier] We updated the accessibility statement"
+ "\A   link text. Use the text \"Accessibility statement\" (EN) "
+ "\A   or \"Declaración de accesibilidad\" (ES) for the required "
+ "\A   link to an accessibility statement."
+ "\A - Guidance [usa-link]: We now suggest using the `rel=\"noreferrer\"`"
+ "\A   property on external links. This prevents the browser from leaking "
+ "\A   information about the original web address."
+ "\A - Guidance [usa-form]: We now suggest identifying both required "
+ "\A   and optional fields in forms. Label required fields with a red"
+ "\A   asterisk and optional fields with the word \"optional\"."
+ "\A --------------------------------------------------------------------"
+ "\A 3.6.0"
+ "\A - Settings: This release updates USWDS disabled color settings"
+ "\A   and tokens to conform to our standard naming convention."
+ "\A   If your project configures disabled color settings"
+ "\A   or uses disabled color tokens, you probably need to update your"
+ "\A   code. Details: github.com/uswds/uswds/releases/tag/v3.6.0"
+ "\A --------------------------------------------------------------------"
+ "\A 3.7.0"
+ "\A Release notes: github.com/uswds/uswds/releases/tag/v3.7.0"
+ "\A "
+ "\A - Accessibility: [usa-identifier] We updated the markup to improve"
+ "\A   how screen readers read the phrase 'An official'."
+ "\A - Accessibility: [usa-range] We updated the markup to remove"
+ "\A   redundant ARIA attributes and improve the screen reader experience."
+ "\A - Accessibility: [usa-range] We added optional data attributes that"
+ "\A   allow adding units to screen reader output."
+ "\A --------------------------------------------------------------------"
+ "\A 3.8.0"
+ "\A Release notes: github.com/uswds/uswds/releases/tag/v3.8.0"
+ "\A "
+ "\A - Accessibility: [usa-layout-docs__sidenav] BREAKING: By default, we"
+ "\A   no longer support using CSS to reorder the sidenav position at mobile"
+ "\A   widths. We suggest that teams update their sidenav markup to better"
+ "\A   support tab order at mobile widths. See the release notes for more"
+ "\A   details on how to do this."
+ "\A   "
+ "\A   To temporarily support the old CSS order functionality,"
+ "\A   set `$theme-sidenav-reorder: true` in your project settings."
+ "\A - Settings: We added `$theme-sidenav-reorder`. When `true` this setting "
+ "\A   uses CSS to reorder the sidenav to follow the page text at mobile widths. "
+ "\A   This setting can introduce usability issues, so we suggest that teams"
+ "\A   update their sidenav markup instead. See the release notes for more detail."
+ "\A "
+ "\A --------------------------------------------------------------------"
+ "\A 3.8.1"
+ "\A Release notes: github.com/uswds/uswds/releases/tag/v3.8.1"
+ "\A "
+ "\A - Content: [usa-identifier] We fixed a bug that added the English word "
+ "\A   \"An\" to Spanish variants of the identifier in our component"
+ "\A   documentation. If you've recently updated the Spanish text of your"
+ "\A   identifier, check to make sure the \"official website\" text begins"
+ "\A   with \"Un sitio web oficial de...\"."
+ "\A "
+ "\A --------------------------------------------------------------------"
+ "\A 3.9.0"
+ "\A Release notes: github.com/uswds/uswds/releases/tag/v3.9.0"
+ "\A "
+ "\A - Accessibility, Display: [usa-header] Removed the CSS order property from the mobile "
+ "\A   view in standard variants of the header component. Now, the visual order of the "
+ "\A   component matches the tab order."
+ "\A - Accessibility, Markup: [usa-footer] Added the autocomplete=\"email\" attribute to "
+ "\A   the big footer variant and the \"Create an account\" template. This attribute allows "
+ "\A   the components to meet the standards outlined in WCAG 1.3.5. Teams should update their "
+ "\A   markup if they use an email field in their big footer."
+ "\A - Accessibility, Markup: [usa-identifier] Updated the USA.gov link in Spanish versions "
+ "\A   of the identifier. The link text now reads \"Visite USAGov en Español\" and the link "
+ "\A   url is now \"https://www.usa.gov/es/\". Teams should update this text if they use the "
+ "\A   Spanish-language identifier."
+ "\A - Accessibility, Markup: [usa-memorable-date] Removed numeric representation of "
+ "\A   months in the memorable date component. Recent usability testing indicated that having "
+ "\A   both numbers and names to represent months was confusing for screen reader users. "
+ "\A   Teams should update their memorable date component to remove the leading numbers."
+ "\A - Display: [usa-alert, usa-site-alert] Fixed a bug that caused"
+ "\A   $theme-site-margins-width to unexpectedly adjust the alignment inside the alert and "
+ "\A   site alert components. Alignment on the alert and site alert components will likely "
+ "\A   shift from this change. Confirm that your implementation of the component aligns "
+ "\A   as expected."
+ "\A - Display: [usa-button] Updated the width of unstyled buttons at narrow screen widths. "
+ "\A   Now, unstyled buttons receive a width of auto to better match USWDS link styles. "
+ "\A   Users should confirm that the variant visually displays as expected in their projects. "
+ "\A - Display: [usa-card] Fixed a bug that caused the component to ignore the "
+ "\A   $theme-card-font-family setting. Confirm that your implementation of the card "
+ "\A   component displays with the expected font family."
+ "\A ";

/* prettier-ignore */
$uswds-notification-disable-message:
  "\A"
+ "\A --------------------------------------------------------------------"
+ "\A These are notifications from the USWDS team, not necessarily a"
+ "\A problem with your code."
+ "\A"
+ "\A Disable notifications using `$theme-show-notifications: false`."
+ "\A --------------------------------------------------------------------\A";

@if $theme-show-notifications {
  @warn "#{$uswds-notifications}"
    + "#{$uswds-notification-disable-message}";
}
