/*
========================================
color
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  color: [value];
----------------------------------------
example:
  .text-black {
    color: black;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/tokens" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-color: (
  color: (
    base: "text",
    modifiers: null,
    values:
      map-collect(
        $tokens-color-global,
        get-palettes($color-palettes),
        get-palettes($global-color-palettes),
        $color-manual-values
      ),
    settings: $color-settings-complete,
    property: "color",
    type: "utility",
  ),
);
