/*
========================================
text-decoration-color
----------------------------------------
usage:
  .underline-[key]
----------------------------------------
output:
  text-decoration-color: [value];
----------------------------------------
example:
  .underline-red {
    text-decoration-color: #e52207;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-text-decoration-color: (
  text-decoration-color: (
    base: "underline",
    modifiers: null,
    values:
      map-collect(
        get-palettes($text-decoration-color-palettes),
        get-palettes($global-color-palettes),
        $text-decoration-color-manual-values
      ),
    settings: $text-decoration-color-settings-complete,
    property: "text-decoration-color",
    type: "utility",
  ),
);
