/*
----------------------------------------
cap-height()
----------------------------------------
Get the cap height of a valid typeface
----------------------------------------
*/

@use "sass:map";
@use "../../tokens";
@use "validate-typeface-token" as *;

@function cap-height($typeface-token) {
  @if not $typeface-token {
    @return false;
  }

  $typeface-token: validate-typeface-token($typeface-token);
  $token-data: map.get(tokens.$all-typeface-tokens, $typeface-token);
  @return map.get($token-data, "cap-height");
}

// @debug cap-height("public-sans");
// @return 362px;
