@use "uswds-core" as *;

/* SITE MIXINS */

@mixin site {

  h1 {
    @include u-font-size('sans', 14);
    line-height: line-height("sans", 1);

    @include at-media-max("mobile-lg") {
      @include u-font-size('sans', 13);
    }
  }

  h2,
  h3,
  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    opacity: 80% !important;
  }

  h2 {
    @include u-font-size("sans", 12);
    line-height: line-height("sans", 1);

    @include at-media-max("mobile-lg") {
      @include u-font-size("sans", 11);
    }
  }

  h3 {
    @include u-font-size("sans", 9);
    font-weight: 400;
    margin-top: 1.5rem;

    @include at-media-max("mobile-lg") {
      @include u-font-size("sans", 8);
    }
  }

  h4 {
    @include u-font-size("sans", 7);
    margin-top: 1.5rem;

    @include at-media-max("mobile-lg") {
      @include u-font-size("sans", 6);
    }
  }

  .usa-prose {
    @include u-font-size('sans', 7);
    line-height: line-height("sans", 4);

    @include at-media-max("mobile-lg") {
      @include u-font-size('sans', 6);
      line-height: line-height("sans", 3);
    }
  }

  .usa-list li {
    @include u-margin-bottom(1);
    max-width: unset;
    line-height: line-height("sans", 2);
  }
}

@mixin site-dark {

  @include site;
  @include u-bg("base-darkest");
  color: color("base-light");

  /* base elements */
  h1,
  h2 {
    color: rgba(230, 199, 76, 0.95);
  }

  h3 {
    color: color("base-lighter");
  }

  p {
    color: inherit;
  }

  /* override, re-style for darkness */
  .usa-prose {
    background-color: unset;
  }

  .usa-link,
  .usa-link--alt,
  .usa-link--external {

    color: inherit !important;
    text-decoration: underline;
    cursor: pointer;

    &:active,
    &:visited {
      color: unset;
    }

    &:hover {
      color: color("base-lightest") !important;
    }
  }
}

.site {
  @include site;
}

.site-dark {
  @include site-dark;
}

/* SITE STYLES */

.pointer {
  cursor: pointer;
}

.learn-more {
  cursor: pointer;
  white-space: nowrap;

  .usa-icon {
    vertical-align: middle;
  }
}

/* show/hide mobile breakpoints */
@include at-media-max("tablet-lg") {
  .not-mobile {
    display: none;
  }
}

@include at-media("tablet-lg") {
  .mobile-only {
    display: none;
  }
}

/* fade in images after loading. */
.easeload {
  opacity: 0;
  transition: all 0.5s ease;
}
