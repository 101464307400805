/*
----------------------------------------
has-important()
----------------------------------------
Check to see if `!important` is
being passed in a mixin's props
----------------------------------------
*/
@use "de-list" as *;
@use "get-last" as *;

@function has-important($props) {
  $props: de-list($props);

  @if get-last($props) == "!important" {
    @return true;
  }

  @return false;
}

// @debug has-important((foo, "!important"));
// @return true
