/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/

@use "sass:meta";
@use "sass:list";

@use "decompose-color-token" as *;

@function color-token-family($color-token) {
  $split: if(
    meta.type-of($color-token) == "list",
    $color-token,
    decompose-color-token($color-token)
  );
  $family: list.nth($split, 1);
  @return $family;
}
