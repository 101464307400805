/*
========================================
z-index
----------------------------------------
usage:
  z-[key]
----------------------------------------
output:
  z-index: [value];
----------------------------------------
example:
  .z-100 {
    z-index: 100;
  }

  .z-top {
    z-index: 99999;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-z-index: (
  z-index: (
    base: "z",
    modifiers: null,
    values: map-collect(get-palettes($z-index-palettes), $z-index-manual-values),
    settings: $z-index-settings-complete,
    property: "z-index",
    type: "utility",
  ),
);
