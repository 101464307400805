/* stylelint-disable */

@use "sass:map";

@use "uswds-core/src/styles/variables/font-type-tokens" as *;
@use "uswds-core/src/styles/mixins/general/font-face" as *;

@each $font-type-token, $metadata in $project-font-type-tokens {
  @if map.get($metadata, "typeface-token") {
    $this-typeface-token: map.get($metadata, "typeface-token");
    $this-src: map.get($metadata, "src");
    @include render-font-face($this-typeface-token, $this-src);
  }
}

/* stylelint-enable */
