/*
----------------------------------------
USWDS THEME SETTINGS (override defaults)
----------------------------------------
https://designsystem.digital.gov/documentation/settings
settings available: @uswds\uswds\packages\uswds-core\src\styles\settings
*/
@use "uswds-core" with (
  $theme-image-path: "/assets/uswds/img",
  $theme-font-path: "/assets/uswds/fonts",
  $theme-show-compile-warnings: false,
  $theme-show-notifications: false,

  // utilities
  $utilities-use-important: true,
  $output-these-utilities:(
    "color",
    "margin",
    "margin-horizontal",
    "margin-vertical",
    "padding",
    "text-align"
  ),

  // typography
  $theme-font-type-sans: "public-sans",
  $theme-font-type-serif: "public-sans",

  // Turn on breakpoints
  $theme-utility-breakpoints: (
    'card':       false,   // 160px
    'card-lg':    false,   // 240px
    'mobile':     false,   // 320px
    'mobile-lg':  true,    // 480px
    'tablet':     true,    // 640px
    'tablet-lg':  true,    // 880px  >> turned on
    'desktop':    true,    // 1024px
    'desktop-lg': false,   // 1200px
    'widescreen': false,   // 1400px
  ),

  $theme-body-background-color: "base-lighter",

  //site theme colors
  $theme-color-base-darker: "gray-80",
  $theme-color-primary-darkest: "blue-90",
  $theme-text-reverse-color: "base-lighter",
  $theme-focus-color: "blue-60",

  // COMPONENT SETTINGS

  // banner
  $theme-banner-background-color: "base-darkest",
  $theme-banner-link-color: "base-light",

  // identifier
  $theme-identifier-background-color: "base-lighter"
);
