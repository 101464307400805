/*
========================================
justify-content
----------------------------------------
usage:
  .flex-justify-[key]
----------------------------------------
output:
  justify-content: [value];
----------------------------------------
example:
  .flex-justify-start {
    justify-content: flex-start;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-justify-content: (
  justify-content: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(
        get-palettes($justify-content-palettes),
        $justify-content-manual-values
      ),
    settings: $justify-content-settings-complete,
    property: "justify-content",
    type: "utility",
  ),
);
