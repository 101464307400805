/*
========================================
line-height
----------------------------------------
usage:
  .line-height-[modifier]-[key]
----------------------------------------
output:
  line-height: [value];
----------------------------------------
example:
  .line-height-sans-2 {
    line-height: 1.1;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-line-height: (
  line-height: (
    base: "line-height",
    modifiers: null,
    values:
      map-collect(
        get-palettes($line-height-palettes),
        $line-height-manual-values
      ),
    settings: $line-height-settings-complete,
    property: "line-height",
    type: "utility",
  ),
);
