/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/

// Deprecated in 3.0.0
$output-all-utilities: true !default;

// Deprecated in 3.1.0
@use "sass:math";

@mixin embed-container($height, $width) {
  padding-bottom: math.percentage(math.div($height, $width));
}

$font-smoothing-warning: "Font smoothing was deprecated in USWDS 3.5.0. Please remove any references to it.";

// Deprecated in 3.5.0
@mixin add-knockout-font-smoothing {
  @warn $font-smoothing-warning;
}

@mixin no-knockout-font-smoothing {
  @warn $font-smoothing-warning;
}
