/*
========================================
border-radius
----------------------------------------
usage:
  .radius-[key]
----------------------------------------
output:
  border-radius: [value];
----------------------------------------
example:
  .radius-0 {
    border-radius: 0;
  }

  .radius-pill {
    border-radius: 99rem;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-border-radius: (
  border-radius: (
    base: "radius",
    modifiers: (
      noModifier: "border-radius",
      "top": (
        "border-top-left-radius",
        "border-top-right-radius",
      ),
      "right": (
        "border-top-right-radius",
        "border-bottom-right-radius",
      ),
      "bottom": (
        "border-bottom-left-radius",
        "border-bottom-right-radius",
      ),
      "left": (
        "border-top-left-radius",
        "border-bottom-left-radius",
      ),
    ),
    property: "",
    values:
      map-collect(
        get-palettes($border-radius-palettes),
        $border-radius-manual-values
      ),
    settings: $border-radius-settings-complete,
    type: "utility",
  ),
);
