/*
========================================
border-color
----------------------------------------
usage:
  .border-[key]
----------------------------------------
output:
  border-color: [value];
----------------------------------------
example:
  .border-red {
    border-color: #e52207;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "uswds-core/src/styles/tokens" as *;
@use "../functions/" as *;

$u-border-color: (
  border-color: (
    property: "border-color",
    base: "border",
    modifiers: null,
    values:
      map-collect(
        $tokens-color-global,
        get-palettes($border-color-palettes),
        get-palettes($global-color-palettes),
        $border-color-manual-values
      ),
    settings: $border-color-settings-complete,
    type: "utility",
  ),
);
