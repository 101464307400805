/*
========================================
outline-color
----------------------------------------
usage:
  .outline-[key]
----------------------------------------
output:
  outline-color: [value];
----------------------------------------
example:
  .outline-yellow {
    outline-color: #fee685;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-outline-color: (
  outline-color: (
    base: "outline",
    modifiers: null,
    values:
      map-collect(
        get-palettes($outline-color-palettes),
        get-palettes($global-color-palettes),
        $outline-color-manual-values
      ),
    settings: $outline-color-settings-complete,
    valuePrepend: null,
    valueAppend: null,
    property: "outline-color",
    type: "utility",
  ),
);
