/*
========================================
circle
----------------------------------------
usage:
  .circle-[key]
----------------------------------------
output:
  height: [value];
  width: [value];
  border-radius: 50%;
----------------------------------------
example:
  .circle-6 {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-circle: (
  circle: (
    base: "circle",
    modifiers: null,
    values: map-collect(get-palettes($circle-palettes), $circle-manual-values),
    settings: $circle-settings-complete,
    property: (
      height,
      width,
    ),
    extend: (
      "border-radius": "50%",
    ),
    type: "utility",
  ),
);
