/*
========================================
background-color
----------------------------------------
usage:
  .bg-[key]
----------------------------------------
output:
  background-color: [value];
----------------------------------------
example:
  .bg-red {
    background-color: #e52207;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-background-color: (
  background-color: (
    base: "bg",
    modifiers: null,
    property: "background-color",
    values:
      map-collect(
        get-palettes($background-color-palettes),
        get-palettes("palette-color-required"),
        get-palettes($global-color-palettes),
        $background-color-manual-values
      ),
    settings: $background-color-settings-complete,
    type: "utility",
  ),
);
