/*
----------------------------------------
rem-to-user-em()
----------------------------------------
Converts a value in rem to a value in
[user-settings] em for use in media
queries
----------------------------------------
*/

@use "sass:math";

@function rem-to-user-em($grid-in-rem) {
  @if unit($grid-in-rem) != "rem" {
    @error 'This value must be in rem';
  }
  $rem-to-user-em: (math.div($grid-in-rem, 1rem)) * 1em;

  @return $rem-to-user-em;
}

// @debug rem-to-user-em(2rem);
// @return 2em
