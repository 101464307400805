/*
========================================
max-height
----------------------------------------
usage:
  .maxh-[key]
----------------------------------------
output:
  max-height: [value];
----------------------------------------
example:
  .maxh-viewport {
    max-height: 100vh;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-max-height: (
  max-height: (
    base: "maxh",
    modifiers: null,
    values:
      map-collect(get-palettes($max-height-palettes), $max-height-manual-values),
    settings: $max-height-settings-complete,
    property: "max-height",
    type: "utility",
  ),
);
