@use "root";
@use "sass:math";

/*
----------------------------------------
px-to-rem()
----------------------------------------
Converts a value in px to a value in rem
----------------------------------------
*/

@function px-to-rem($pixels) {
  @if not $pixels {
    @return false;
  }
  $px-to-rem: (math.div($pixels, root.$root-font-size-equiv)) * 1rem;
  $px-to-rem: math.div(math.round($px-to-rem * 100), 100);

  @return $px-to-rem;
}

// @debug px-to-rem(16px);
// @return 1rem
