/*
========================================
bottom
----------------------------------------
usage:
  .bottom-[key]
----------------------------------------
output:
  bottom: [value];
----------------------------------------
example:
  .bottom-3 {
    bottom: 1.5rem;
  }

  .bottom-neg-2px {
    bottom: -2px;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-bottom: (
  bottom: (
    base: "bottom",
    modifiers: null,
    values: map-collect(get-palettes($bottom-palettes), $bottom-manual-values),
    settings: $bottom-settings-complete,
    property: "bottom",
    type: "utility",
  ),
);
