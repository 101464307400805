/*
========================================
flex-wrap
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex-wrap: [value];
----------------------------------------
example:
  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-no-wrap {
    flex-wrap: nowrap;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-flex-wrap: (
  flex-wrap: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(get-palettes($flex-wrap-palettes), $flex-wrap-manual-values),
    settings: $flex-wrap-settings-complete,
    property: "flex-wrap",
    type: "utility",
  ),
);
