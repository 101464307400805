/*
========================================
white-space
----------------------------------------
usage:
  text-[key]
----------------------------------------
output:
  white-space: [value];
----------------------------------------
example:
  .text-pre-wrap {
    white-space: pre-wrap;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-whitespace: (
  whitespace: (
    base: "text",
    modifiers: null,
    values:
      map-collect(get-palettes($whitespace-palettes), $whitespace-manual-values),
    settings: $whitespace-settings-complete,
    property: "white-space",
    type: "utility",
  ),
);
