/*
========================================
font-style
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  font-style: [value];
----------------------------------------
example:
  .text-italic {
    font-style: italic;
  }

  .text-no-italic {
    font-style: normal;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-font-style: (
  font-style: (
    base: "text",
    modifiers: null,
    values:
      map-collect(get-palettes($font-style-palettes), $font-style-manual-values),
    settings: $font-style-settings-complete,
    property: "font-style",
    type: "utility",
  ),
);
