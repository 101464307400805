/*
----------------------------------------
USWDS BASE STYLES (select packages)
----------------------------------------
ref: https://designsystem.digital.gov/components/packages
*/
@use "uswds-core" as *;

/* GLOBAL ELEMENTS */
@forward "uswds-global"; // core, fonts, elements, helpers
@forward "uswds-utilities";
@forward "uswds-typography";

// must be loaded entirely
@forward "usa-modal";

/* GLOBAL HTML STYLES */
body {
  margin: 0;
  overflow-y: scroll;
}

main {
  display: block;
  min-height: 85vh;

  @include at-media-max("tablet") {
    min-height: 70vh;
  }
}
