/*
----------------------------------------
Measure
----------------------------------------
*/

$system-measure-smaller: 44ex;
$system-measure-small: 60ex;
$system-measure-base: 64ex;
$system-measure-large: 68ex;
$system-measure-larger: 72ex;
$system-measure-largest: 88ex;
