/*
========================================
text-transform
----------------------------------------
usage:
  .text-[key]
----------------------------------------
output:
  text-transform: [value];
----------------------------------------
example:
  .text-uppercase {
    text-transform: uppercase;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-text-transform: (
  text-transform: (
    base: "text",
    modifiers: null,
    values:
      map-collect(
        get-palettes($text-transform-palettes),
        $text-transform-manual-values
      ),
    settings: $text-transform-settings-complete,
    property: "text-transform",
    type: "utility",
  ),
);
