/*
========================================
display
----------------------------------------
usage:
  .display-[key]
----------------------------------------
output:
  display: [value];
----------------------------------------
example:
  .display-none {
    display: none;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-display: (
  display: (
    base: "display",
    modifiers: null,
    values: map-collect(get-palettes($display-palettes), $display-manual-values),
    settings: $display-settings-complete,
    property: "display",
    type: "utility",
  ),
);
