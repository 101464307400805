/*
----------------------------------------
is-system-color-token()
----------------------------------------
Return whether a token is a system
color token
----------------------------------------
*/

@use "sass:map";
@use "../../tokens/color/shortcodes-color-system" as *;

@function is-system-color-token($token) {
  @if map.has-key($system-color-shortcodes, $token) {
    @return true;
  }
  @return false;
}

// @debug is-system-color-token("red-cool-5v");
// @return true;
