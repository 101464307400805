/*
========================================
flex-direction
----------------------------------------
usage:
  .flex-[key]
----------------------------------------
output:
  flex-direction: [value];
----------------------------------------
example:
  .flex-row {
    flex-direction: row;
  }

  .flex-column {
    flex-direction: column;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-flex-direction: (
  flex-direction: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(
        get-palettes($flex-direction-palettes),
        $flex-direction-manual-values
      ),
    settings: $flex-direction-settings-complete,
    property: "flex-direction",
    type: "utility",
  ),
);
