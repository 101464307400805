/*
========================================
opacity
----------------------------------------
usage:
  .opacity-[key]
----------------------------------------
output:
  opacity: [value];
----------------------------------------
example:
  .opacity-0 {
    opacity: 0;
  }

  .opacity-30 {
    opacity: 0.3;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-opacity: (
  opacity: (
    base: "opacity",
    modifiers: null,
    values: map-collect(get-palettes($opacity-palettes), $opacity-manual-values),
    settings: $opacity-settings-complete,
    property: "opacity",
    type: "utility",
  ),
);
