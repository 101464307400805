/*
========================================
align-self
----------------------------------------
usage:
  .flex-align-self-[key]
----------------------------------------
output:
  align-self: [value];
----------------------------------------
example:
  .flex-align-self-start {
    align-self: flex-start;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-align-self: (
  align-self: (
    base: "flex",
    modifiers: null,
    values:
      map-collect(get-palettes($align-self-palettes), $align-self-manual-values),
    settings: $align-self-settings-complete,
    property: "align-self",
    type: "utility",
  ),
);
