/*
----------------------------------------
strip-unit()
----------------------------------------
Remove the unit of a length
@author Hugo Giraudel
@param {Number} $number - Number to remove unit from
@return {Number} - Unitless number
----------------------------------------
*/

@use "sass:meta";
@use "sass:math";

@function strip-unit($number) {
  @if meta.type-of($number) == "number" and not math.is-unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

// @debug strip-unit(10px);
// @return 10;
