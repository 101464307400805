/*
----------------------------------------
get-last()
----------------------------------------
Return the last item of a list,
Return null if the value is null
----------------------------------------
*/

@use "sass:list";

@function get-last($props) {
  $length: list.length($props);
  $last: if($length == 0, null, list.nth($props, -1));

  @return $last;
}

// @debug get-last((1, 2, 3));
// @return 3;
