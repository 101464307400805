/*
========================================
border
----------------------------------------
Also applies `solid`
Needs to come before other border utils
----------------------------------------
usage:
  .border-[modifier]*-[key]
----------------------------------------
output:
  border-[modifier]: [value];
----------------------------------------
example:
  .border-top-1px {
    border-top: 1px solid;
  }

  .border-0 {
    border: 0 solid;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-border: (
  border: (
    base: "border",
    modifiers: (
      noModifier: "",
      "y": (
        "-top",
        "-bottom",
      ),
      "x": (
        "-left",
        "-right",
      ),
      "top": "-top",
      "right": "-right",
      "bottom": "-bottom",
      "left": "-left",
    ),
    values: map-collect(get-palettes($border-palettes), $border-manual-values),
    valueAppend: " solid",
    settings: $border-settings-complete,
    property: "border",
    type: "utility",
  ),
);
