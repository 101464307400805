/*
----------------------------------------
Luminance ranges
----------------------------------------
*/

$system-color-grades: (
  100: (
    "min": 0,
    "max": 0,
  ),
  90: (
    "min": 0.005,
    "max": 0.015,
  ),
  80: (
    "min": 0.02,
    "max": 0.04,
  ),
  70: (
    "min": 0.05,
    "max": 0.07,
  ),
  60: (
    "min": 0.1,
    "max": 0.125,
  ),
  50: (
    "min": 0.175,
    "max": 0.183,
  ),
  40: (
    "min": 0.225,
    "max": 0.3,
  ),
  30: (
    "min": 0.35,
    "max": 0.45,
  ),
  20: (
    "min": 0.5,
    "max": 0.65,
  ),
  10: (
    "min": 0.75,
    "max": 0.82,
  ),
  5: (
    "min": 0.85,
    "max": 0.93,
  ),
  0: (
    "min": 1,
    "max": 1,
  ),
);
