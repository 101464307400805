/*
========================================
order
----------------------------------------
usage:
  .order-[key]
----------------------------------------
output:
  order: [value];
----------------------------------------
example:
  .order-first {
    order: -1;
  }

  .order-2 {
    order: 2;
  }
----------------------------------------
*/

@use "uswds-core/src/styles/settings" as *;
@use "uswds-core/src/styles/functions" as *;
@use "../functions/" as *;

$u-order: (
  order: (
    base: "order",
    modifiers: null,
    values: map-collect(get-palettes($order-palettes), $order-manual-values),
    settings: $order-settings-complete,
    property: "order",
    type: "utility",
  ),
);
